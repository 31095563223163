import { Auth } from 'aws-amplify';

let socket;
const prompt_input_form = document.getElementById('prompt_input');
const prompt_text = document.getElementById('prompt_text');
const responseOutput = document.getElementById('responseOutput');
const summaryEditor = document.getElementById('summaryEditor');
const spinner = document.getElementById('spinner');
const prompt_question = document.getElementById('prompt_question');

const websocket_url = 'wss://ae35r9qkwc.execute-api.us-west-2.amazonaws.com/production';

socket = new WebSocket(websocket_url);
console.log('Connecting...');

socket.onopen = function(event) {
  console.log('Connected');
};

socket.onclose = function(event) {
  console.log('Disconnected');
};

socket.onerror = function(event) {
  console.error('Error: ' + event);
};

socket.onmessage = function(event) {
  console.log('Received: ' + event.data);
  // const responseBody = JSON.parse(event.data);
  // let content = responseBody.response.choices[0].message.content.replace(/\n/g, '<br>');
  let content = event.data.replace(/\n/g, '<br>');

  summaryEditor.innerHTML = content;
  console.log("prompt text value")
  console.log(prompt_text.value)
  prompt_question.innerHTML = prompt_text.value;
  console.log("prompt question")
  console.log(prompt_question)
  spinner.style.display = 'none';
  responseOutput.style.display = 'flex';
};

function log(text) {
  const log = document.getElementById('prompt_question');
  log.innerHTML += '<br>' + text;
  log.scrollTop = log.scrollHeight; // Scroll to bottom
}

async function gptWebSocketRequest(prompt_value) {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();
  const accessToken = session.getAccessToken().getJwtToken();
  let user_info = await Auth.currentAuthenticatedUser();  
  const userId = user_info.attributes.sub;

  const messageObject = {
    action: 'sendmessage',
    user_name: userId,
    msg: prompt_value
  };
  console.log("Message Object:")
  console.log(messageObject);

  socket.send(JSON.stringify(messageObject));
  log('Sent: ' + JSON.stringify(messageObject));
  console.log('Sent: ' + JSON.stringify(messageObject));

}

prompt_input_form.addEventListener('keydown', (event) => {
  if (event.key === 'Enter' && event.target !== prompt_text) {
    event.preventDefault();
  }
});

prompt_input_form.addEventListener('submit', async (event) => {
  event.preventDefault();
  event.stopPropagation();

  spinner.style.display = 'flex';

  try { 
    responseOutput.style.display = 'none';
    const prompt_value = prompt_text.value.replace(/[^a-zA-Z0-9 .,;]/g, ' ');
    console.log("prompt value");
    console.log(prompt_value);
    const response = await gptWebSocketRequest(prompt_value);
    console.log("const response?");
    console.log(response);
    // await gptWebSocketRequest(prompt_value);

    // Resetting input after submission
    // prompt_text.value = '';
  } catch (error) {
    console.error('Error:', error);
  }
});

