// console.log("app.js started...");
import  jsPDF from 'jspdf';
import { Amplify } from "aws-amplify";
import { Auth } from 'aws-amplify';
import aws_exports from "./aws-exports";
import { userAuthState } from './auth_user';
import { checkAuthContent } from './auth_content';
import { signUp, confirmSignUp, resendConfirmationCode } from './auth_signup';
import { signIn } from './auth_login';
import { forgotPass, confirmForgotPass } from './auth_forgot_password';
import { signOut } from './auth_logout';
import awsconfig from './aws-exports';
import exportPdf from './export';
import { gptFetchRequest } from "./laplinkfetch";
import Chart from 'chart.js/auto'

(async function() {
  const data = [
    { year: 2010, count: 10 },
    { year: 2011, count: 20 },
    { year: 2012, count: 15 },
    { year: 2013, count: 25 },
    { year: 2014, count: 22 },
    { year: 2015, count: 30 },
    { year: 2016, count: 28 },
  ];

  new Chart(
    document.getElementById('acquisitions'),
    {
      type: 'bar',
      data: {
        labels: data.map(row => row.year),
        datasets: [
          {
            label: 'Acquisitions by year',
            data: data.map(row => row.count)
          }
        ]
      }
    }
  );
})();


const amplifyConfig = {
  Auth: {
    identityPoolId: 'us-west-2:3b88070e-15c6-4be1-8912-87470ad32bde',
    region: 'us-west-2'
  }
}

//Initialize Amplify
Amplify.configure(amplifyConfig);

// async function checkUser() {
//   let user = await Auth.currentAuthenticatedUser();  
//   // console.log("username...................................................................");
//   // console.log(user.attributes);
//   // console.log(user.attributes.email);
//   const useremail = user.attributes.email;
//   document.getElementById("useremail").innerHTML = useremail;
// };

async function checkUser() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    // console.log("username...................................................................");
    // console.log(user.attributes);
    // console.log(user.attributes.email);
    const useremail = user.attributes.email;
    document.getElementById("useremail").innerHTML = useremail;
    return user.attributes;
  } catch (error) {
    console.log('User not logged in.');
    return null;
  }
};

const { attributes } = checkUser();

Amplify.configure(awsconfig);
Amplify.configure(aws_exports);
checkAuthContent();

window.addEventListener('DOMContentLoaded', (event) => {
  const exportPdfButton = document.getElementById('exportPdfButton');
  // console.log('Export button:', exportPdfButton);
  exportPdfButton.addEventListener('click', exportPdf);
});

// document.getElementById("request-access-form").addEventListener("submit", function(event) {
//   // Stop the form from submitting normally
//   event.preventDefault();

//   // Capture form fields value
//   let firstName = document.getElementsByName("firstName")[0].value;
//   let lastName = document.getElementsByName("lastName")[0].value;
//   let company = document.getElementsByName("company")[0].value;
//   let jobtitle = document.getElementsByName("jobtitle")[0].value;
//   let email = document.getElementsByName("email")[0].value;
//   let phone = document.getElementsByName("phone")[0].value;

//   // Construct the JSON object to send to the server
//   let data = {
//       firstName: firstName,
//       lastName: lastName,
//       company: company,
//       jobtitle: jobtitle,
//       email: email,
//       phone: phone
//   };

//   // Send the POST request
//   fetch("https://y3wy6hn9b0.execute-api.us-west-2.amazonaws.com/contactMeFormDeployAPI/contactmeform", {
//       method: "POST",
//       headers: {
//           "Content-Type": "application/json"
//       },
//       body: JSON.stringify(data)
//   }).then(function(response) {
//       return response.json();
//   }).then(function(data) {
//       console.log(data);
//   }).catch(function(error) {
//       console.error('Error:', error);
//   });
// });

// document.getElementById("request-access-form").addEventListener("submit", function(event) {
//   // Stop the form from submitting normally
//   event.preventDefault();

//   // Capture form fields value
//   let firstName = document.getElementsByName("firstName")[0].value;
//   let lastName = document.getElementsByName("lastName")[0].value;
//   let company = document.getElementsByName("company")[0].value;
//   let jobtitle = document.getElementsByName("jobtitle")[0].value;
//   let email = document.getElementsByName("email")[0].value;
//   let phone = document.getElementsByName("phone")[0].value;

//   // Construct the JSON object to send to the server
//   let data = {
//       firstName: firstName,
//       lastName: lastName,
//       company: company,
//       jobtitle: jobtitle,
//       email: email,
//       phone: phone
//   };

//   // Show the spinner
//   document.getElementById("request-access-form").style.display = "none";
//   document.getElementById("spinner").style.display = "block";

//   // Send the POST request
//   fetch("https://y3wy6hn9b0.execute-api.us-west-2.amazonaws.com/contactMeFormDeployAPI/contactmeform", {
//       method: "POST",
//       headers: {
//           "Content-Type": "application/json"
//       },
//       body: JSON.stringify(data)
//   }).then(function(response) {
//       // Hide the spinner
//       document.getElementById("spinner").style.display = "none";

//       return response.json();
//   }).then(function(data) {
//       // Show the success message
//       alert("Email sent successfully!");
//       console.log(data);
//   }).catch(function(error) {
//       // Hide the spinner
//       document.getElementById("spinner").style.display = "none";

//       // Show the error message
//       alert("An error occurred. Please try again.");
//       console.error('Error:', error);
//   });
// });

$('#request-access-form').validate();
document.getElementById("request-access-form").addEventListener("submit", function(event) {
  event.preventDefault();
  // First, check if the form is valid using jQuery's validate method
  // if (!$(this).valid()) {
  //   event.preventDefault(); // Prevent form submission if the form is not valid
  //   return; // Exit the function
  // }

  // Capture form fields value
  let formData = {
      firstName: document.getElementsByName("firstName")[0].value,
      lastName: document.getElementsByName("lastName")[0].value,
      // company: document.getElementsByName("company")[0].value,
      // jobtitle: document.getElementsByName("jobtitle")[0].value,
      email: document.getElementsByName("email")[0].value,
      phone: document.getElementsByName("phone")[0].value
  };
  if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone) {
    alert("Please enter all required fields...");
    return false;
  };
  // Check if the form is valid
  if (!$(this).valid()) {
    event.preventDefault(); // Prevent form submission if the form is not valid
    return; // Exit the function
}
  // Show the spinner
  document.getElementById("request-access-form").style.display = "none";
  document.getElementById("spinner").style.display = "block";

  fetch("https://y3wy6hn9b0.execute-api.us-west-2.amazonaws.com/contactMeFormDeployAPI/contactmeform", {
      method: "POST",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
  }).then(function(response) {
      console.log('Response:', response);
      document.getElementById("spinner").style.display = "none";
      document.getElementById("request-access-form").style.display = "none";
      document.getElementById("submission-response-status").style.display = "block";
      if (response.ok) {
          showCustomAlert('success', 'Contact Sent: CloudRaven Support have been notified of your interest.');
      } else {
          showCustomAlert('danger', 'An error occurred with your form submission. Please try again.');
      }
  }).catch(function(error) {
      document.getElementById("spinner").style.display = "none";
      document.getElementById("request-access-form").style.display = "block";
      showCustomAlert('danger', 'An error occurred with your form submission. Please try again.');
      console.error('Error:', error);
  });
});

function showCustomAlert(type, message) {
  console.log("showCustomAlert called with type:", type); // Debugging
  // Hide all alerts
  document.querySelectorAll('.alert').forEach(alert => alert.style.display = 'none');

  // Show the specific alert
  var alertDiv = document.querySelector('.alert.alert-' + type);
  if (alertDiv) {
      alertDiv.style.display = 'block';
      alertDiv.querySelector('strong').textContent = message;
  }
}


// console.log("app.js finished...");
