import Quill from 'quill';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';

const exportPdf = () => {
  // Get the Quill editor content
  const editor = new Quill('#coverLetterEditor');
  const content = editor.getContents();
  const plainText = editor.getText();
  const html = editor.root.innerHTML;

  // Convert the HTML to a PDF document
  const pdfDoc = new jsPDF('p', 'pt', 'letter');
  const options = {
    margin: [90, 50],
    filename: 'cover-letter.pdf',
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { dpi: 72, letterRendering: true },
    jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all'] }
  };
  html2pdf().set(options).from(html).toContainer().toCanvas().toPdf().get('pdf').then((pdf) => {
    // Save the PDF document
    pdf.save('document.pdf');
  });
}
  

export default exportPdf;