console.log("auth_login.js loaded...");

import { Auth } from 'aws-amplify';
// import Amplify, { Analytics } from 'aws-amplify';

// Analytics.record({ name: 'appLogin' });

// Sign In function
export const signIn = async ({username, password}) => {
    try {
        const { user } = await Auth.signIn(username, password);
        // console.log(user)
        // alert("user signed in");

        Auth.currentSession().then(res=>{
            let accessToken = res.getAccessToken()
            let jwt = accessToken.getJwtToken()
            //You can print them to see the full objects
            // console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
            // console.log(`myJwt: ${jwt}`)
            // console.log(`sub: ${JSON.stringify(accessToken.payload.sub)}`)
          })
        window.location = '/main.html'
    } catch (error) {
        console.log('error signing in', error);
        alert(error.message);
        window.location = '/login.html'
    }
}

// Event Listeners if user is on Login page
if (document.querySelector("#auth-login")) {

    document.querySelector("#form-auth-login").addEventListener("click", event => {
        event.preventDefault();
    });

    document.querySelector("#btnLogin").addEventListener("click", () => {
        const username = document.querySelector("#formLoginEmail").value
        const password = document.querySelector("#formLoginPassword").value
        // console.log({username, password});
        signIn({username, password});
    });
};