import { userAuthState } from './auth_user';
// import Amplify, { Analytics } from 'aws-amplify';

// Analytics.record({ name: 'authVisit' });



export function checkAuthContent() {
// If not authenticated, pages with containing the id of 'authenticated-content' will redirect to login.html.
    if (document.querySelector("#authenticated-content")) {
        userAuthState()
            .then(data => {
                // Analytics.record({ name: 'authContentSuccess' });
                // console.log('user is authenticated: ', data);
                // const useremail = {data};
                  
                // document.getElementById("useremail").innerHTML = useremail;
                // console.log("Useremail id assigned.")
                // console.log(data)
                // console.log(`myAccessToken: ${JSON.stringify(useremail)}`)
                // console.log(`myJwt: ${jwt}`)
                // console.log(`sub: ${JSON.stringify(useremail.payload.sub)}`)
            })
            .catch(error => {
                // Analytics.record({ name: 'authContentError' });
                // console.log('user is not authenticated: ', error);
                // Since this is the secret page and the user is not authenticated, redirect to the login page.
                alert("You are not authenticated and will be redirected to login again.");
                window.location = '/login.html';
            });
    } else {
        // Merely putting this here so that the authentication state of other pages can be seen in Developer Tools
        userAuthState()
            .then(data => {
                // console.log('user is authenticated: ', data);
            })
            .catch(error => {
                // console.log('user is not authenticated: ', error);
            });
    }
}